.notificationList {
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.calendarItem {
  display: flex;
  width: 100%;
  background-color: white;
  text-decoration: none;
  color: black;
  padding: 2px;
  margin: 2px;
  background: #cdeccd;
  padding-left: 30px;
}

.calendarItem .content {
  display: flex;
  align-items: center;
  width: 100%;
}

.calendarItem .data {
  width: 92%;
  float: left;
}

.calendarItem .description {

}

.calendarItem .expiry {
  font-size: 14px;

}

.calendarItem .removeButton {
  width: 40px;
  height: 40px;
}

.calendarItem .removeButton:hover {
  background-color: #9ed29e;
}

.defaultButton {
  margin: 20px 10px 20px 10px;
  padding: 5px;
  font-size: 16px;
}

