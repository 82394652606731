body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: white;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}